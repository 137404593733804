<template>
    <Layout>
        <b-form @submit.prevent>
            <div class="card">
                <div class="card-header">
                    <h4 class="card-title">{{ $t('profile.modify') }}</h4>
                </div>
                <div class="card-body">
                    <b-row>
                        <b-col cols="12" md="2">
                            <b-form-group
                                id="name-prefix"
                                class="row"
                                :label="$t('profile.name_prefix')"
                                label-for="name-prefix-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="name-prefix-input" v-model="model.name_prefix" :placeholder="$t('profile.placeholders.name_prefix')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                            <b-form-group
                                id="lastname"
                                class="row"
                                :label="$t('profile.lastname')"
                                label-for="lastname-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="lastname-input" v-model="model.lastname" :placeholder="$t('profile.placeholders.lastname')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="5">
                            <b-form-group
                                id="firstname"
                                class="row"
                                :label="$t('profile.firstname')"
                                label-for="firstname-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="firstname-input" v-model="model.firstname" :placeholder="$t('profile.placeholders.firstname')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group
                                id="email"
                                class="row"
                                :label="$t('profile.email')"
                                label-for="email-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="email-input" type="email" v-model="model.email" :placeholder="$t('profile.placeholders.email')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group
                                id="phone"
                                class="row"
                                :label="$t('profile.phone')"
                                label-for="phone-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <vue-tel-input id="phone-input" v-model="model.phone" :defaultCountry="defaultLocale" mode="international" validCharactersOnly :inputOptions="{placeholder: $t('profile.placeholders.phone')}"></vue-tel-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="card-header">
                    <h4 class="card-title">{{ $t('profile.invoice_data') }}</h4>
                </div>
                <div class="card-body">
                    <b-row>
                        <b-col cols="12" md="8">
                            <b-form-group
                                id="company-name"
                                class="row"
                                :label="$t('profile.company_name')"
                                label-for="company-name-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="company-name-input" v-model="model.company_name" :placeholder="$t('profile.placeholders.company_name')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group
                                id="vat-number"
                                class="row"
                                :label="$t('profile.vat_number')"
                                label-for="vat-number-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <template #label>
                                    {{ $t('profile.vat_number') }}
                                    <small class="text-muted ml-2"><i>({{ $t('profile.if_necessary') }})</i></small>
                                </template>
                                <b-form-input id="vat-number-input" v-model="model.vat_number" :placeholder="$t('profile.placeholders.vat_number')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="2">
                            <b-form-group
                                id="zipcode"
                                class="row"
                                :label="$t('profile.zipcode')"
                                label-for="zipcode-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="zipcode-input" v-model="model.zipcode" :placeholder="$t('profile.placeholders.zipcode')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="4">
                            <b-form-group
                                id="cityname"
                                class="row"
                                :label="$t('profile.cityname')"
                                label-for="cityname-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="cityname-input" v-model="model.cityname" :placeholder="$t('profile.placeholders.cityname')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" md="6">
                            <b-form-group
                                id="address"
                                class="row"
                                :label="$t('profile.address_details')"
                                label-for="address-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="address-input" v-model="model.address" :placeholder="$t('profile.placeholders.address_details')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </div>
                <div class="card-footer text-end">
                    <b-button variant="primary" @click="save">
                        <i class="fas fa-save me-2"></i>{{ $t('profile.save') }}</b-button>
                </div>
            </div>
        </b-form>

        <b-form @submit.prevent>
            <b-card>
                <template #header>
                    <b-card-title>{{ $t('profile.change_password') }}</b-card-title>
                </template>
                <template #default>
                    <b-row>
                        <b-col cols="12" lg="4">
                            <b-form-group
                                id="is-two-factor-auth-input"
                            >
                                <div class="form-check form-switch form-switch-md mb-2">
                                    <label class="form-check-label" for="is-two-factor-auth">{{ $t('login.two_factor_auth') }}</label>
                                    <input type="checkbox" id="is-two-factor-auth" name="is-two-factor-auth" class="form-check-input" v-model="model.is_two_factor_auth" />
                                </div>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template #footer>
                    <div class="text-end">
                        <b-button variant="primary" @click="save2FA"><i class="fas fa-save me-2"></i>{{ $t('profile.save') }}</b-button>
                    </div>
                </template>
            </b-card>
        </b-form>

        <b-form @submit.prevent>
            <b-card>
                <template #header>
                    <b-card-title>{{ $t('profile.change_password') }}</b-card-title>
                </template>
                <template #default>
                    <b-row>
                        <b-col cols="12" lg="4">
                            <b-form-group
                                id="password"
                                class="row"
                                :label="$t('profile.current_password')"
                                label-for="password-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="password-input" type="password" v-model="chPswModel.old_passwd" autocomplete="off" :placeholder="$t('profile.placeholders.current_password')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group
                                id="new-password"
                                class="row"
                                :label="$t('profile.new_password')"
                                label-for="new-password-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="new-password-input" type="password" v-model="chPswModel.new_passwd" autocomplete="off" :placeholder="$t('profile.placeholders.new_password')"></b-form-input>
                            </b-form-group>
                        </b-col>
                        <b-col cols="12" lg="4">
                            <b-form-group
                                id="new-password-confirmation"
                                class="row"
                                :label="$t('profile.repeat_new_password')"
                                label-for="new-password-confirmation-input"
                                label-cols="12"
                                content-cols="12"
                            >
                                <b-form-input id="new-password-confirmation-input" type="password" v-model="chPswModel.new_passwd_re" autocomplete="off" :placeholder="$t('profile.placeholders.repeat_new_password')"></b-form-input>
                            </b-form-group>
                        </b-col>
                    </b-row>
                </template>
                <template #footer>
                    <div class="text-end">
                        <b-button variant="primary" @click="changePassword"><i class="fas fa-save me-2"></i>{{ $t('profile.save') }}</b-button>
                    </div>
                </template>
            </b-card>
        </b-form>
    </Layout>
</template>

<script>
import Layout from "../../layouts/table";
import {mapGetters} from "vuex";

export default {
    name: "UserForm",

    components: {
        Layout,
    },

    computed: {
        ...mapGetters({
            user: 'auth/currentUser'
        }),

        defaultLocale: function () {
            return navigator.language
        }
    },

    data: function () {
        return {
            model: {
                phone: ''
            },
            chPswModel: {}
        }
    },

    methods: {
        setModel: function (model = {}) {
            this.model = Object.assign({phone: ''}, model);
        },

        save: function () {
            this.$store.dispatch('user/update', this.model).then(response => {
                const model = JSON.parse(JSON.stringify(response));
                model.realname = [model.name_prefix, model.lastname, model.firstname].filter(item => !!item).join(' ')
                this.$store.dispatch('auth/updateCurrentUser', model)
            });
        },

        changePassword: function () {
            this.$store.dispatch('auth/changePassword', this.chPswModel);
        },

        save2FA: function () {
            this.$store.dispatch('auth/change2FA', this.model.is_two_factor_auth);
        },
    },

    watch: {
        user: {
            immediate: true,
            deep: true,
            handler: function (val) {
                this.setModel(val)
                this.chPswModel.m_id = val?.m_id
            },
        }
    },

    created() {
        document.title = this.setTitle(this.$t('profile.profile'));
    }
}
</script>

<style scoped>
    input::placeholder {
        color: lightgrey;
    }
</style>
